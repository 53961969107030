<template>
  <div>
    <v-card
      style="
        background: rgba(61, 61, 61, 0.3) !important;
        background-color: rgba(61, 61, 61, 0.3) !important;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        border: 1px solid rgba(255, 255, 255, 0.27);
        height: 100vh;
      "
    >
      <v-toolbar dense flat color="transparent">
        <v-toolbar-title>
          <v-icon class="mr-2">view_in_ar</v-icon>
          Container Management
          <span v-if="container.containerNo">
            : {{ container.containerNo }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip
          v-if="
            !editModeEnabled &&
            shipment &&
            shipment.documentationStatus == 'OPEN'
          "
          color="blue"
          class="mx-1"
          @click="enableEditMode"
        >
          Enable Edit Mode
        </v-chip>
        <v-chip
          v-else-if="
            editModeEnabled &&
            shipment &&
            shipment.documentationStatus == 'OPEN'
          "
          color="success"
          class="mx-1"
          @click="enableEditMode"
        >
          Edit Mode Active
        </v-chip>
        <v-chip v-else class="mx-1">
          <v-icon left color="amber" small>lock</v-icon>
          Shipment Locked
        </v-chip>
        <v-btn
          icon
          class="mx-1"
          :disabled="updatedPallets.length == 0"
          color="primary"
          :loading="savingPallets"
          @click="saveChanges"
        >
          <v-icon>save</v-icon>
        </v-btn>
        <v-btn text @click="close()"> X </v-btn>
      </v-toolbar>
      <v-card-text v-if="container" class="ma-0 pa-0">
        <v-container style="height: 100%; width: 100%" class="my-0 py-0">
          <v-row justify="center">
            <!-- <v-col cols="12" sm="6" md="2" class="mx-0 px-0">
              <v-list-item style="height: 35px" class="mx-0 px-0">
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 14px; font-weight: bold"
                    >{{ container.containerNo }}</v-list-item-title
                  >
                  <v-list-item-subtitle style="font-size: 11px"
                    >Container No</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col> -->
            <v-col cols="12" sm="6" md="2">
              <v-list-item style="height: 35px">
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 14px; font-weight: bold"
                    >{{ container.ctoNo }}</v-list-item-title
                  >
                  <v-list-item-subtitle style="font-size: 11px"
                    >CTO No</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-list-item style="height: 35px">
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px; font-weight: bold"
                    >{{ container.sealNo }}
                    <span v-if="!container.sealNo">-</span>
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px"
                    >Seal No</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-list-item style="height: 35px">
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 14px; font-weight: bold"
                    >{{ container.billOfLadingNo }}
                    <span v-if="!container.billOfLadingNo">-</span>
                    </v-list-item-title
                  >
                  <v-list-item-subtitle style="font-size: 11px"
                    >Bill of Lading No</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="2"
              v-if="
                container.booking && container.booking.carrierReferenceNumber
              "
            >
              <v-list-item style="height: 35px">
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 14px; font-weight: bold"
                    >{{
                      container.booking.carrierReferenceNumber
                    }}
                     <span v-if="!container.booking.carrierReferenceNumber">-</span>
                    </v-list-item-title
                  >
                  <v-list-item-subtitle style="font-size: 11px"
                    >Carrier Reference No</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="2"
              v-if="container.booking && container.booking.contractNumber"
            >
              <v-list-item style="height: 35px">
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 14px; font-weight: bold"
                    >{{ container.booking.contractNumber }}
                    <span v-if="!container.booking.contractNumber">-</span>
                    
                    </v-list-item-title
                  >
                  <v-list-item-subtitle style="font-size: 11px"
                    >Contract No</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="2"
              v-if="container.customerContainerRef"
            >
              <v-list-item style="height: 35px">
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 14px; font-weight: bold"
                    >{{ container.customerContainerRef }}</v-list-item-title
                  >
                  <v-list-item-subtitle style="font-size: 11px"
                    >Customer Reference</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-container>
        <ag-grid-vue
          groupDisplayType="multipleColumns"
          :animateRows="false"
          :rowGroupPanelShow="'always'"
          :autoSizeStrategy="{
            type: 'fitCellContents',
            columnLimits: [
              {colId: 'additional',
                minWidth: 150
              },
              {
                colId: 'sscc',
                minWidth: 150,
              },
              {
                colId: 'productName',
                minWidth: 200,
              },
              {
                colId: 'varietyProductName',
                minWidth: 200,
              },
            ],
          }"
          style="width: 100%; height: 88vh"
          :enableRangeSelection="true"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="25"
          :columnDefs="columns"
          :rowData="container.containerPallets"
          :defaultColDef="defaultColDef"
          :editType="'fullRow'"
          :statusBar="statusBar"
          :sideBar="sidebar"
          :getRowId="getRowId"
          :stopEditingWhenCellsLoseFocus="true"
          :initialState="initialState"
          @grid-ready="onGridReady"
          @cellValueChanged="onCellValueChanged"
          @state-updated="onStateUpdated"
        >
        </ag-grid-vue>
      </v-card-text>
    </v-card>

    <v-dialog v-model="confirmCloseModal" width="500px">
      <v-card>
        <v-toolbar flat dense color="transparent">
          <v-toolbar-title>Confirm Close</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="confirmCloseModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p>
            You have unsaved changes for {{ updatedPallets.length }} line items.
            Are you sure you want to close?
          </p>
          <v-container>
            <v-row justify="center">
              <v-btn
                small
                outlined
                class="mx-1"
                color="error"
                @click="confirmCloseModal = false"
                >No</v-btn
              >
              <v-btn
                small
                outlined
                class="mx-1"
                color="success"
                @click="close(true)"
                >Yes</v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import ActionPanel from "./ActionPanel.vue";
import AdditionalAttachments from "./AdditionalAttachments.vue";
import ProductSelector from "./ProductSelector.vue";
export default {
  name: "ContainerManagement",
  props: {
    container: {
      type: Object,
      required: true,
    },
    shipment: {
      type: Object,
      required: true,
    },
  },
  components: {
    ActionPanel,
    AdditionalAttachments,
    AgGridVue,
    ProductSelector,
  },
  data: () => ({
    aggFuncs: {},
    columns: null,
    confirmCloseModal: false,
    containerDetail: null,
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    editModeEnabled: false,
    gridApi: null,
    gridColumnApi: null,
    initialState: {},
    products: [],
    savingPallets: false,
    sidebar: {},
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
    updatedPallets: [],
  }),
  watch: {
    "container.id": {
      immediate: true,
      async handler(val){
        if(val){
          if(!this.containerDetail || (val && this.containerDetail && val != this.containerDetail.id)){
          this.containerDetail = await this.$API.getContainer(val);
        }
      }
    }
    },
    "shipment.id": {
      immediate: true,
      async handler(val) {
        this.editModeEnabled = false;
        if (val) {
          if (this.products.length == 0) {
            let products = await this.$API.getProducts({});
            this.products = products.data;
          }
          if(!this.containerDetail && this.container && this.container.id){
            this.containerDetail = await this.$API.getContainer(this.container.id);
          }
          this.columns = [
            {
              headerName: "SSCC",
              field: "sscc",
              filter: "agSetColumnFilter",
            },
            {
              headerName: "Additional",
              field: "additional",
              enableRowGroup: false,
              filter: false,
              sortable: false,
              cellRenderer: "AdditionalAttachments",
              cellRendererParams: {
                detail: this.getContainerDetail
              },              
            },
            {
              headerName: "Sequence",
              field: "sequenceNumber",
              cellDataType: "number",
              filter: "agSetColumnFilter",
            },
            {
              headerName: "PUC",
              field: "farm",
              filter: "agSetColumnFilter",
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "PHC",
              field: "packHouse",
              filter: "agSetColumnFilter",
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Orchard",
              field: "orchard",
              filter: "agSetColumnFilter",
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Phyto Data",
              field: "phytoReference",
              filter: "agSetColumnFilter",
              editable: () => {
                return this.editMode;
              },
            },

            {
              headerName: "Production Area",
              field: "productionArea",
              filter: "agSetColumnFilter",
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Product",
              field: "productId",
              valueFormatter: (params) => {
                return params.data ? params.data.productId : params.value  ? this.getProductName(params.value): '';
              },
              valueGetter: (params) => {
                return params.data ? params.data.productId : params.value  ? params.value: ''
              },
              cellRenderer: "ProductSelector",
              cellRendererParams: (p) => {
                return p.data
                  ? {
                      isEditable: this.editMode,
                      type: "productId",
                      productId: p.data.productId,
                      products: this.products.filter(
                        (x) => x.type == "Product"
                      ),
                      setProduct: this.setProduct,
                    }
                  : null;
              },
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Variety",
              field: "varietyProductId",
              // valueFormatter: (params) => {
              //   return params.data
              //     ? this.getProductName(params.data.varietyProductId)
              //     : params.value
              //     ? this.getProductName(params.value)
              //     : "";
              // },
              // valueGetter: (params) => {
              //   return params.data ? params.data.varietyProductId : null;
              // },
              valueFormatter: (params) => {
                let result = params.data ? this.getProductName(params.data.varietyProductId) : params.value  ? this.getProductName(params.value): '';
                // console.log("Variety Formatter", result)
                return result
              },
              valueGetter: (params) => {
                let result = params.data ? params.data.varietyProductId : params.value  ? params.value: ''
                // console.log("Variety Getter", result)
                return result
              },
              cellRenderer: "ProductSelector",
              filter: "agSetColumnFilter",

              cellRendererParams: (p) => {
                return p.data
                  ? {
                      isEditable: this.editMode,
                      type: "varietyProductId",
                      parentProductId: p.data.productId,
                      productId: p.data.varietyProductId,
                      products: this.products,
                      setProduct: this.setVariety,
                    }
                  : null;
              },
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Flesh Colour",
              field: "fleshColour",
              filter: "agSetColumnFilter",
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Class",
              field: "gradeClassCode",
              filter: "agSetColumnFilter",
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Count",
              field: "countSize",
              filter: "agSetColumnFilter",

              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Cartons",
              field: "noCartons",
              aggFunc: "sum",
              cellDataType: 'number',
              cellEditor: "agNumberCellEditor",
              cellEditorParams: {
                min: 0,
              },
              editable: () => {
                return this.editMode;
              }
            },
            {
              headerName: "Pack Code",
              field: "packCode",
              filter: "agSetColumnFilter",
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Pack Type",
              field: "packType",
              filter: "agSetColumnFilter",
              cellEditor: "agSelectCellEditor",
              cellEditorParams: { values: ["CARTON", "BIN", "BAG"] },
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Nett (KG)",
              field: "nettWeight",
              filter: "agSetColumnFilter",
              aggFunc: "sum",
              cellEditor: "agNumberCellEditor",
              cellEditorParams: {
                min: 0,
              },
              valueGetter: (params) => {
                return params.data
                  ? parseFloat(params.data.nettWeight) || 0
                  : 0;
              },
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Gross (KG)",
              field: "grossWeight",
              filter: "agSetColumnFilter",
              aggFunc: "sum",
              cellEditor: "agNumberCellEditor",
              cellEditorParams: {
                min: 0,
              },
              valueGetter: (params) => {
                return params.data
                  ? parseFloat(params.data.grossWeight) || 0
                  : 0;
              },
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Stuff Date",
              field: "stuffDate",
              filter: "agDateColumnFilter",
              valueFormatter: (params) => {
                if (!params.value) {
                  return "";
                }
                let date = new Date(params.value);
                const month = date.getMonth() + 1;
                const day = date.getDate();
                return `${date.getFullYear()}-${
                  month < 10 ? "0" + month : month
                }-${day < 10 ? "0" + day : day}`;
              },
              cellEditor: "agDateStringCellEditor",
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Insp. Date",
              field: "inspectionDate",
              filter: "agDateColumnFilter",
              valueFormatter: (params) => {
                if (!params.value) {
                  return "";
                }
                let date = new Date(params.value);

                const month = date.getMonth() + 1;
                const day = date.getDate();
                return `${date.getFullYear()}-${
                  month < 10 ? "0" + month : month
                }-${day < 10 ? "0" + day : day}`;
              },
              cellEditor: "agDateStringCellEditor",
              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Manifest No.",
              field: "inspectionManifestNo",
              filter: "agSetColumnFilter",

              editable: () => {
                return this.editMode;
              },
            },
            {
              headerName: "Insp. Point",
              field: "inspectionPoint",
              editable: () => {
                return this.editMode;
              },
            },
          ];
        }
      },
    },
  },
  computed: {
    editMode() {
      return (
        this.editModeEnabled &&
        this.shipment &&
        this.shipment.documentationStatus == "OPEN"
      );
    },
  },
  created() {
    this.aggFuncs = {
      sumCartons: this.sumCartons,
      sumNettWeight: this.sumNettWeight,
      sumGrossWeight: this.sumGrossWeight,
    };
    this.sidebar = {
      toolPanels: [
        {
          id: "actions",
          labelDefault: "Actions",
          labelKey: "actions",
          iconKey: "chart",
          toolPanel: "ActionPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
          toolPanelParams: {
            container: this.container,
            detail: this.getContainerDetail,
            editMode: () => {
              return this.editMode;
            },
            shipment: this.shipment,
            getFilteredData: this.getFilteredData,
            refreshContainerDetail: async () => {
              this.$emit("refreshContainer", this.container.id);
              this.containerDetail = await this.$API.getContainer(
                this.container.id
              );
              this.gridApi.refreshCells({ force: true });
              this.gridApi.refreshToolPanel({force: true});
              for(let item of this.container.containerPallets){
                const rowNode = this.gridApi.getRowNode(item.id);
                if (rowNode) {
                  rowNode.setData(item)
                  this.gridApi.refreshCells({ rowNodes: [rowNode] });
                }
              }
            },
            containerSourceChanged: (id) => {
              this.$emit("containerSourceChanged", id);
            },
            resetTable: () => {
              this.resetTable();
            },
            exportExcel: () => {
              this.gridApi.exportDataAsExcel();
            },
            exportCSV: () => {
              this.gridApi.exportDataAsCsv();
            },
            setWeights: this.setWeights,
          },
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      defaultToolPanel: "actions",
    };
    if (
      localStorage.getItem("palletEditTable_" + this.$store.state.currentOrg.id)
    ) {
      let state = JSON.parse(
        localStorage.getItem(
          "palletEditTable_" + this.$store.state.currentOrg.id
        )
      );
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
  },
  methods: {
    isCellEditable(id, colKey) {
      const rowNode = this.gridApi.getRowNode(id);
      const colDef = this.gridColumnApi.getColumn(colKey).getColDef();
      if (typeof colDef.editable === "function") {
        // Execute the function with the appropriate params
        return colDef.editable({
          node: rowNode,
          data: rowNode.data,
          colDef: colDef,
          column: this.gridColumnApi.getColumn(colKey),
          api: this.gridApi,
          columnApi: this.gridColumnApi,
          context: this.context,
        });
      } else {
        // If it's a boolean, return its value
        return !!colDef.editable;
      }
    },
    close(force = false) {
      if (!force) {
        if (this.updatedPallets.length > 0) {
          this.confirmCloseModal = true;
        } else {
          this.$emit("close");
        }
      } else {
        this.confirmCloseModal = false;
        this.$emit("close");
      }
    },
    enableEditMode() {
      this.editModeEnabled = !this.editModeEnabled;
      this.gridApi.refreshCells({
        cols: ["productId", "varietyProductId"],
        force: true,
      });
    },
    getContainer() {
      return this.container
    },
    getContainerDetail() {
      return this.containerDetail;
    },
    getFilteredData() {
      let rowData = [];
      const rowCount = this.gridApi.getDisplayedRowCount();
      for (let i = 0; i < rowCount; i++) {
        const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
        if(rowNode.data){
          rowData.push(rowNode.data);
        } else if(rowNode.allLeafChildren && rowNode.allLeafChildren.length > 0){
          rowData = [...rowData, ...rowNode.allLeafChildren.map(x => x.data)]
        }
      }
      let uniqueIds = [...new Set(rowData.map((x) => x.id))];

      return uniqueIds.map((id) => {
        let find = rowData.find((x) => x.id == id);
        return find;
      });
    },
    getProductName(id) {
      let find = this.products.find((x) => x.id == id);
      return find ? find.name : "";
    },
    getRowId(params) {
      return params.data.id;
    },
    onCellValueChanged(params) {
      const rowNode = this.gridApi.getRowNode(params.data.id);
      if (rowNode) {
        rowNode.setDataValue(params.colDef.field, params.newValue);
        this.gridApi.refreshCells({ rowNodes: [rowNode] });
      }
      let findPallet = this.updatedPallets.findIndex(
        (x) => x.id == params.data.id
      );
      if (findPallet > -1) {
        this.updatedPallets[findPallet][params.colDef.field] = params.newValue;
      } else {
        this.updatedPallets.push({
          id: params.data.id,
          [params.colDef.field]: params.newValue,
        });
      }
    },
    onStateUpdated(state) {
      localStorage.setItem(
        "palletEditTable_" + this.$store.state.currentOrg.id,
        JSON.stringify({
          columnOrder: state.state.columnOrder,
          columnSizing: state.state.columnSizing,
          columnGroup: state.state.columnGroup,
          coumnPinning: state.state.coumnPinning,
          rowGroup: state.state.rowGroup,
          sort: state.state.sort,
        })
      );
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    resetTable() {
      this.gridApi.setFilterModel(null);
      this.gridApi.setRowGroupColumns([]);
      this.gridApi.applyColumnState({ state: this.columns });
      this.gridApi.refreshCells({ force: true });
    },
    async saveChanges() {
      this.savingPallets = true;
      await this.$API.bulkUpdatePallets(this.updatedPallets);
      this.$emit("containerSourceChanged", this.container.id)
      this.$message.success("Pallets updated successfully!");
      this.updatedPallets = [];
      this.savingPallets = false;
    },
    setProduct(item, product) {
      const rowNode = this.gridApi.getRowNode(item.id);
      if (rowNode) {
        rowNode.setDataValue("productId", product.id);
        this.gridApi.refreshCells({ rowNodes: [rowNode] });
      }
      let findPallet = this.updatedPallets.findIndex((x) => x.id == item.id);
      if (findPallet > -1) {
        this.updatedPallets[findPallet].productId = product.id;
      } else {
        this.updatedPallets.push({
          id: item.id,
          productId: product.id,
        });
      }
    },
    setVariety(item, product) {
      const rowNode = this.gridApi.getRowNode(item.id);
      if (rowNode) {
        rowNode.setDataValue("varietyProductId", product.id);
        this.gridApi.refreshCells({ rowNodes: [rowNode] });
      }
      let findPallet = this.updatedPallets.findIndex((x) => x.id == item.id);
      if (findPallet > -1) {
        this.updatedPallets[findPallet].varietyProductId = product.id;
      } else {
        this.updatedPallets.push({
          id: item.id,
          varietyProductId: product.id,
        });
      }
    },
    setWeights(pallets) {
      let updateNodes = [];
      for (let pallet of pallets) {
        const rowNode = this.gridApi.getRowNode(pallet.id);
        if (rowNode) {
          rowNode.setDataValue("grossWeight", pallet.grossWeight);
          rowNode.setDataValue("nettWeight", pallet.nettWeight);
          updateNodes.push(rowNode);
        }
        let findPallet = this.updatedPallets.findIndex(
          (x) => x.id == pallet.id
        );
        if (findPallet > -1) {
          this.updatedPallets[findPallet].grossWeight = pallet.grossWeight;
          this.updatedPallets[findPallet].nettWeight = pallet.nettWeight;
        } else {
          this.updatedPallets.push({
            id: pallet.id,
            grossWeight: pallet.grossWeight,
            nettWeight: pallet.nettWeight,
          });
        }
      }
      if (updateNodes.length > 0)
        this.gridApi.refreshCells({ rowNodes: updateNodes });
    },
    sumCartons(data) {
      console.log(data);
    },
    sumNettWeight(data) {
      console.log(data);
    },
    sumGrossWeight(data) {
      console.log(data);
    },
  },
};
</script>
<style scoped>
.editable-cell {
  background-color: #f0f0f0 !important;
  background: #f0f0f0 !important;
}
</style>