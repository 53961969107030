var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticStyle:{"background":"rgba(61, 61, 61, 0.3) !important","background-color":"rgba(61, 61, 61, 0.3) !important","border-radius":"16px","box-shadow":"0 4px 30px rgba(0, 0, 0, 0.1)","backdrop-filter":"blur(15px)","-webkit-backdrop-filter":"blur(15px)","border":"1px solid rgba(255, 255, 255, 0.27)","height":"100vh"}},[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-toolbar-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("view_in_ar")]),_vm._v(" Container Management "),(_vm.container.containerNo)?_c('span',[_vm._v(" : "+_vm._s(_vm.container.containerNo)+" ")]):_vm._e()],1),_c('v-spacer'),(
          !_vm.editModeEnabled &&
          _vm.shipment &&
          _vm.shipment.documentationStatus == 'OPEN'
        )?_c('v-chip',{staticClass:"mx-1",attrs:{"color":"blue"},on:{"click":_vm.enableEditMode}},[_vm._v(" Enable Edit Mode ")]):(
          _vm.editModeEnabled &&
          _vm.shipment &&
          _vm.shipment.documentationStatus == 'OPEN'
        )?_c('v-chip',{staticClass:"mx-1",attrs:{"color":"success"},on:{"click":_vm.enableEditMode}},[_vm._v(" Edit Mode Active ")]):_c('v-chip',{staticClass:"mx-1"},[_c('v-icon',{attrs:{"left":"","color":"amber","small":""}},[_vm._v("lock")]),_vm._v(" Shipment Locked ")],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","disabled":_vm.updatedPallets.length == 0,"color":"primary","loading":_vm.savingPallets},on:{"click":_vm.saveChanges}},[_c('v-icon',[_vm._v("save")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" X ")])],1),(_vm.container)?_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-container',{staticClass:"my-0 py-0",staticStyle:{"height":"100%","width":"100%"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-list-item',{staticStyle:{"height":"35px"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.container.ctoNo))]),_c('v-list-item-subtitle',{staticStyle:{"font-size":"11px"}},[_vm._v("CTO No")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-list-item',{staticStyle:{"height":"35px"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.container.sealNo)+" "),(!_vm.container.sealNo)?_c('span',[_vm._v("-")]):_vm._e()]),_c('v-list-item-subtitle',{staticStyle:{"font-size":"11px"}},[_vm._v("Seal No")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-list-item',{staticStyle:{"height":"35px"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.container.billOfLadingNo)+" "),(!_vm.container.billOfLadingNo)?_c('span',[_vm._v("-")]):_vm._e()]),_c('v-list-item-subtitle',{staticStyle:{"font-size":"11px"}},[_vm._v("Bill of Lading No")])],1)],1)],1),(
              _vm.container.booking && _vm.container.booking.carrierReferenceNumber
            )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-list-item',{staticStyle:{"height":"35px"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.container.booking.carrierReferenceNumber)+" "),(!_vm.container.booking.carrierReferenceNumber)?_c('span',[_vm._v("-")]):_vm._e()]),_c('v-list-item-subtitle',{staticStyle:{"font-size":"11px"}},[_vm._v("Carrier Reference No")])],1)],1)],1):_vm._e(),(_vm.container.booking && _vm.container.booking.contractNumber)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-list-item',{staticStyle:{"height":"35px"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.container.booking.contractNumber)+" "),(!_vm.container.booking.contractNumber)?_c('span',[_vm._v("-")]):_vm._e()]),_c('v-list-item-subtitle',{staticStyle:{"font-size":"11px"}},[_vm._v("Contract No")])],1)],1)],1):_vm._e(),(_vm.container.customerContainerRef)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-list-item',{staticStyle:{"height":"35px"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.container.customerContainerRef))]),_c('v-list-item-subtitle',{staticStyle:{"font-size":"11px"}},[_vm._v("Customer Reference")])],1)],1)],1):_vm._e()],1)],1),_c('ag-grid-vue',{staticStyle:{"width":"100%","height":"88vh"},attrs:{"groupDisplayType":"multipleColumns","animateRows":false,"rowGroupPanelShow":'always',"autoSizeStrategy":{
          type: 'fitCellContents',
          columnLimits: [
            {colId: 'additional',
              minWidth: 150
            },
            {
              colId: 'sscc',
              minWidth: 150,
            },
            {
              colId: 'productName',
              minWidth: 200,
            },
            {
              colId: 'varietyProductName',
              minWidth: 200,
            },
          ],
        },"enableRangeSelection":true,"undoRedoCellEditing":true,"undoRedoCellEditingLimit":25,"columnDefs":_vm.columns,"rowData":_vm.container.containerPallets,"defaultColDef":_vm.defaultColDef,"editType":'fullRow',"statusBar":_vm.statusBar,"sideBar":_vm.sidebar,"getRowId":_vm.getRowId,"stopEditingWhenCellsLoseFocus":true,"initialState":_vm.initialState},on:{"grid-ready":_vm.onGridReady,"cellValueChanged":_vm.onCellValueChanged,"state-updated":_vm.onStateUpdated}})],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.confirmCloseModal),callback:function ($$v) {_vm.confirmCloseModal=$$v},expression:"confirmCloseModal"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v("Confirm Close")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.confirmCloseModal = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('p',[_vm._v(" You have unsaved changes for "+_vm._s(_vm.updatedPallets.length)+" line items. Are you sure you want to close? ")]),_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","outlined":"","color":"error"},on:{"click":function($event){_vm.confirmCloseModal = false}}},[_vm._v("No")]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","outlined":"","color":"success"},on:{"click":function($event){return _vm.close(true)}}},[_vm._v("Yes")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }