<template>
  <div style="width: 100%">
    <v-list dense style="width: 100%">
      <v-subheader style="font-size: 14px">
        <v-icon color="secondary" class="mr-2">insights</v-icon>
        Actions</v-subheader
      >
      <v-divider></v-divider>
      <v-list-item @click="exportCSV" style="height: 35px">
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px">
            Export data to CSV
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="exportExcel" style="height: 35px">
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px">
            Export data to Excel
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="resetTable" style="height: 35px">
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px">
            Reset Table
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-subheader style="font-size: 14px">
        <v-icon color="secondary" class="mr-2">scale</v-icon>
        Weights</v-subheader
      >
      <v-divider></v-divider>

      <v-list-item @click="editWeight('grossWeight')" style="height: 35px">
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px">
            {{ calculateGrossWeight }} KG
          </v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px"
            >Gross Weight</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="editWeight('nettWeight')" style="height: 35px">
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px">
            {{ calculateNettWeight }} KG
          </v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px"
            >Nett Weight</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="editPackWeight()" style="height: 35px">
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px">
            Set Pack Weights
          </v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px"
            >Set the pack code weight per carton/unit</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-subheader style="font-size: 14px" :key="dataSourceKey">
        <v-icon color="secondary" class="mr-2">bar_chart</v-icon>
        Data Source(s)
        <v-spacer></v-spacer>
        <v-btn
          v-if="dataSourceChange"
          :loading="savingDataSource"
          @click="updateDataSource()"
          color="blue"
          icon
        >
          <v-icon>save</v-icon>
        </v-btn>
      </v-subheader>
      <v-divider></v-divider>
      <v-progress-linear
        v-if="loadingDataSource"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <v-list-item
        v-for="source in availableSources"
        :key="source.id"
        style="border-bottom: 0.5px solid grey"
      >
        <v-list-item-content
          style="cursor: pointer"
          @click="viewSource(source)"
        >
          <v-list-item-title>
            {{ source.type }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="source.locationCode">
            <v-icon class="mr-2" color="grey" small>location_on</v-icon>
            {{ source.locationCode }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="source.transactionTime" style="font-size: 11px">
            <v-icon class="mr-2" color="grey" small>schedule</v-icon>
            {{ source.transactionTime }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="text-wrap" style="font-size: 11px">
            <v-icon class="mr-2" color="grey" small>description</v-icon
            >{{ source.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch
            color="success"
            v-model="source.active"
            @change="checkDataSourceChanges()"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="!loadingDataSource && availableSources.length == 0">
        <v-list-item-content class="text-center">
          <span style="color: grey; font-size: 12px"
            >No data sources found</span
          >
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-subheader style="font-size: 14px">
        <v-icon color="secondary" class="mr-2">agriculture</v-icon>
        Removal Permits <v-spacer></v-spacer>
        <v-btn @click="addRemovalPermit()" color="primary" small icon
          ><v-icon>add_circle_outline</v-icon></v-btn
        >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on" small
              icon
              :loading="loadingContainerDocumentPack"
              @click="createContainerDocumentPack()"
              ><v-icon>picture_as_pdf</v-icon></v-btn
            >
          </template>
          <span style="font-size: 12px">Create Removal Permit Pack</span>
        </v-tooltip>
      </v-subheader>
      <v-divider></v-divider>
      <v-list dense class="my-0 py-0" :key="removalPermitKey">
      <v-list-item v-for="(permit, index) in removalPermitFarms" :key="index">
        <v-list-item-action class="mx-0 px-0">
          <v-icon small :color="permit && new Date(permit.expiry) > new Date()?'success':'error'">
            fiber_manual_record
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px">
            {{ permit.farm }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="permit.permitNo" style="font-size: 11px">
            {{ permit.permitNo }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="permit.expiry" style="font-size: 11px">
            <span v-if="new Date(permit.expiry) > new Date()"
              >Valid until: {{ permit.expiry }}</span
            >
            <span v-else style="color: red">Expired: {{ permit.expiry }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <v-btn
              v-if="permit.permitDocument"
              icon small
              @click="viewRemovalPermit(permit.permitDocument, permit.farm)"
              ><v-icon>launch</v-icon></v-btn
            >
        </v-list-item-action>
      </v-list-item>
    </v-list>
    </v-list>

    <v-dialog v-model="sourceModal" fullscreen persistent>
      <v-card v-if="sourceModal">
        <v-card-title>
          Manage Data Source
          <v-spacer></v-spacer>
          <v-btn text @click="(sourceModal = false), (selectedContainer = null)"
            >X</v-btn
          >
        </v-card-title>
        <v-card-subtitle>
          {{ sourceItem.name }}
          <br />
          <v-icon class="mr-2" small color="grey">label</v-icon>
          {{ sourceItem.type }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12" v-if="sourceItem.sourceType == 'stockFile'">
              <v-data-table
                :items="sourceItem.contents"
                :headers="importHeaders"
                height="82vh"
                fixed-header
                dense
                disable-pagination
                hide-default-footer
              ></v-data-table>
            </v-col>
            <v-col cols="12" v-else>
              <v-row>
                <v-col cols="12" sm="2">
                  <v-list dense>
                    <v-subheader style="font-size: 16px"
                      >Containers</v-subheader
                    >
                    <v-divider></v-divider>
                    <v-list-item
                      v-for="container in sourceItem.stockFileContainers"
                      :key="container.id"
                      @click="selectedContainer = container"
                      :style="{
                        'border-left':
                          selectedContainer &&
                          selectedContainer.id == container.id
                            ? '3px solid var(--v-primary-base)'
                            : '',
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ container.containerNo }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-data-table
                    v-if="selectedContainer"
                    :items="selectedContainer.contents"
                    :headers="importHeaders"
                    height="82vh"
                    fixed-header
                    dense
                    disable-pagination
                    hide-default-footer
                  ></v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="weightModal" width="600px">
      <v-card v-if="weightItem">
        <v-toolbar flat dense>
          <v-toolbar-title> Edit Weight </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="updateWeight()" color="primary"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="(weightModal = false), (weightItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-subtitle>
          This value will be divided proportionally among all pallets.
        </v-card-subtitle>
        <v-card-text class="my-0 py-0">
          <v-list dense class="my-0 py-0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Gross Weight </v-list-item-title>
                <v-list-item-subtitle>
                  Current: {{ weightItem.currentGross }} KG
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-text-field
                  outlined
                  v-model="weightItem.newGross"
                  dense
                  hide-details
                  suffix="KG"
                  label="Gross Weight"
                ></v-text-field>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Nett Weight </v-list-item-title>
                <v-list-item-subtitle>
                  Current: {{ weightItem.currentNett }} KG
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-text-field
                  outlined
                  v-model="weightItem.newNett"
                  dense
                  hide-details
                  suffix="KG"
                  label="Nett Weight"
                ></v-text-field>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="packCodeModal"
      width="600px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat dense color="transparent">
          <v-toolbar-title> Set Pack Code Weights </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" icon @click="setPackCodeWeights()"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="(packCodeModal = false), (uniquePackCodes = [])"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-subtitle>
          These are the weights per carton/item.
        </v-card-subtitle>
        <v-card-text>
          <ag-grid-vue
            :animateRows="false"
            :autoSizeStrategy="{
              type: 'fitGridWidth',
              columnLimits: [],
            }"
            style="width: 100%; height: 250px"
            :undoRedoCellEditing="true"
            :undoRedoCellEditingLimit="25"
            :columnDefs="columns"
            :rowData="uniquePackCodes"
            :editType="'fullRow'"
            :getRowId="getRowId"
            :stopEditingWhenCellsLoseFocus="true"
            @grid-ready="onGridReady"
            @cellValueChanged="onCellValueChanged"
          >
          </ag-grid-vue>
          <!-- <v-row v-for="(packCode, index) in uniquePackCodes" :key="index">
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field style="font-size: 12px" outlined :label="packCode.name + ' NETT'" hide-details v-model="packCode.nettWeight" dense
                  suffix="KG"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field style="font-size: 12px" outlined :label="packCode.name + ' GROSS'" hide-details v-model="packCode.grossWeight" dense
                  suffix="KG"></v-text-field>
              </v-col>
            </v-row> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="removalPermitModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          Attach Removal Permit: {{ removalPermitItem.farm }}
          <v-spacer></v-spacer
          ><v-btn
            text
            @click="(removalPermitModal = false), (removalPermitItem = {})"
            >X</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col
              cols="12"
              sm="12"
              v-if="
                !removalPermitItem.selectedDocument || removalPermitPage == 0
              "
              :key="removalPermitFormKey"
            >
              <v-list>
                <v-subheader style="font-size: 16px"
                  >Select a Document
                </v-subheader>
                <v-list-item v-if="filterDocuments.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No documents</span>
                  </v-list-item-content></v-list-item
                >
                <v-list dense style="max-height: 45vh; overflow-y: auto">
                  <v-list-item
                    v-for="document in filterDocuments"
                    :key="document.id"
                    @click="
                      (removalPermitItem.selectedDocument = document),
                        removalPermitFormKey++,
                        (removalPermitPage = 1)
                    "
                    :style="{
                      'border-left':
                        removalPermitItem.selectedDocument &&
                        document.id == removalPermitItem.selectedDocument.id
                          ? '3px solid var(--v-primary-base)'
                          : '',
                    }"
                  >
                    <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                      <v-img
                        class="mr-2"
                        style="height: 30px; width: 30px"
                        :src="getIcon(document.name)"
                      >
                      </v-img>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-left"
                        style="font-size: 14px"
                      >
                        {{ document.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        style="font-size: 12px"
                        v-if="document.user"
                      >
                        <v-icon small>person</v-icon>
                        {{ document.user.firstname }}
                        {{ document.user.surname }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px" v-else>
                        System Generated
                      </v-list-item-subtitle>

                      <v-list-item-subtitle
                        style="font-size: 12px"
                        v-if="document.isFinancialDocument"
                      >
                        <v-icon small class="mr-1">account_balance</v-icon>
                        Financial Document
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        {{ $Format.formatDate(document.createdAt).default }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-row justify="center" class="mt-2">
                  <v-btn
                    class="mt-2"
                    outlined
                    :disabled="!removalPermitItem.selectedDocument"
                    color="primary"
                    @click="removalPermitPage++"
                    >Next <v-icon right small>chevron_right</v-icon></v-btn
                  >
                </v-row>

                <file-upload
                  class="my-0 py-0"
                  post-action=""
                  :multiple="false"
                  :drop="true"
                  :drop-directory="true"
                  v-model="removalPermitList"
                  ref="permitUploader"
                >
                </file-upload>
              </v-list>
            </v-col>
            <v-col cols="12" v-else-if="removalPermitPage == 1">
              <v-subheader>Select applicable PUC's</v-subheader>

              <v-list dense style="max-height: 40vh; overflow-y: auto">
                <v-list-item
                  v-for="(permit, index) in removalPermitFarms"
                  :key="index" style="height: 35px"
                >
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 12px">
                      {{ permit.farm }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox
                      v-model="removalPermitItem.selectedFarms"
                      :value="permit.farm" dense
                      @change="removalPermitFormKey++"
                    ></v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-row justify="center">
                <v-btn
                  class="mt-2 mx-1"
                  outlined
                  color="primary"
                  @click="removalPermitPage--"
                >
                  <v-icon left small>chevron_left</v-icon> Back</v-btn
                >
                <v-btn
                  class="mt-2 mx-1"
                  outlined
                  :disabled="removalPermitItem.selectedFarms.length == 0"
                  color="primary"
                  @click="removalPermitPage++"
                  >Next <v-icon right small>chevron_right</v-icon></v-btn
                >
              </v-row>
            </v-col>
            <v-col
              cols="12"
              v-else-if="removalPermitPage == 2"
              class="text-center"
            >
              <v-text-field
                label="Permit No"
                v-model="removalPermitItem.permitNo"
                outlined
                clearable
                dense hide-details
              ></v-text-field>
              <v-subheader>Select Expiry Month:</v-subheader>
              <v-date-picker
                v-model="removalPermitItem.selectedDate"
                type="month"
              ></v-date-picker>
              <v-row justify="center" class="my-2">
                <v-btn
                  class="mt-2 mx-1"
                  outlined
                  color="primary"
                  @click="removalPermitPage--"
                  ><v-icon small left>chevron_left</v-icon> Back</v-btn
                >
                <v-btn
                  class="mt-2 mx-1"
                  outlined
                  :disabled="
                    !removalPermitItem.permitNo ||
                    !removalPermitItem.selectedDate
                  "
                  color="success"
                  :loading="submittingPermit"
                  @click="submitRemovalPermit()"
                  >Submit</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card></v-dialog
    >
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import FileUpload from "vue-upload-component";

export default {
  components: {
    AgGridVue,
    FileUpload,
  },
  data: () => ({
    activeSources: [],
    availableSources: [],
    columns: [
      {
        headerName: "Pack Code",
        field: "name",
        editable: false,
      },
      {
        headerName: "Nett Weight (KG)",
        field: "nettWeight",
        cellDataType: "number",
        editable: true,
      },
      {
        headerName: "Gross Weight (KG)",
        field: "grossWeight",
        cellDataType: "number",
        editable: true,
      },
    ],
    dataSourceChange: false,
    dataSourceKey: 100,
    detail: null,
    filterDocuments: [],
    filteredPallets: [],
    gridApi: null,
    importHeaders: [],
    item: null,
    loadingContainerDocumentPack: false,
    loadingDataSource: false,
    loadingDocuments: false,
    packCodeModal: false,
    removalPermitItem: {
      selectedDocument: null,
      selectedFarms: [],
      permitNo: null,
      selectedDate: null,
    },
    removalPermitList: [],
    removalPermitModal: false,
    removalPermitFormKey: 200,
    removalPermitPage: 0,
    removalPermitKey: 300,
    savingDataSource: false,
    sourceItem: null,
    sourceModal: false,
    selectedContainer: null,
    submittingPermit: false,
    uniquePackCodes: [],
    weightItem: {},
    weightModal: false,
  }),
  mounted() {
    this.item = this.params.container;
    if(this.item){
      this.detail = this.params.detail()
    if (this.item && this.item.id && this.filterDocuments.length == 0) {
      this.loadDocuments();
    }
    this.getAvailableSources();
    }
    
  },
  watch: {
    removalPermitList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitPermitFiles();
        }
      },
    },
  },
  computed: {
    calculateGrossWeight() {
      if (this.item) {
        let result = this.item.containerPallets
          .map((x) => parseFloat(x.grossWeight))
          .reduce((a, b) => a + b, 0);
        return result.toFixed(2);
      } else return 0;
    },
    calculateNettWeight() {
      if (this.item) {
        let result = this.item.containerPallets
          .map((x) => parseFloat(x.nettWeight))
          .reduce((a, b) => a + b, 0);
        return result.toFixed(2);
      } else return 0;
    },
    removalPermitFarms() {
      let item = this.params.detail() ?? this.item
      if (item) {
        let uniqueFarms = [
          ...new Set(item.containerPallets.map((x) => x.farm)),
        ];
        let result = [];
        for (let i = 0; i < uniqueFarms.length; i++) {
          let find = item.containerPallets.find(
            (x) => x.farm == uniqueFarms[i] && x.permitDocumentId
          );
          if (find) {
            result.push({
              permitDocumentId: find.permitDocumentId,
              farm: uniqueFarms[i],
              permitNo: find.permitNo,
              expiry: find.permitExpiryDate,
              permitDocument: find.permitDocument,
            });
          } else {
            result.push({
              farm: uniqueFarms[i],
              permitNo: null,
              expiry: null,
            });
          }
        }
        return result;
      } else return [];
    },
  },
  async created() {
    let headers = await this.$API.getMappingHeaders();
    this.importHeaders = headers.map((x) => ({
      text: x.name,
      value: x.field,
      align: "center",
    }));
  },
  methods: {
    addPermitFiles() {
      this.$refs.permitUploader.$el.children[0].click();
    },
    addRemovalPermit() {
      this.removalPermitItem = {
        selectedDocument: null,
        selectedFarms: [],
        permitNo: null,
        selectedDate: null,
      };
      this.removalPermitModal = true;
    },
    checkDataSourceChanges() {
      this.dataSourceChange = false;
      for (let i = 0; i < this.availableSources.length; i++) {
        let find = this.activeSources.findIndex(
          (x) => x.stockFileId == this.availableSources[i].id
        );
        if (this.availableSources[i].active && find == -1) {
          this.dataSourceChange = true;
          break;
        } else if (!this.availableSources[i].active && find > -1) {
          this.dataSourceChange = true;
          break;
        }
      }
      this.dataSourceKey++;
    },
    async createContainerDocumentPack() {
      this.loadingContainerDocumentPack = true;
      let result = await this.$API.createDocumentPack({
        type: "permitDocument",
        bookingContainerId: this.item.id,
        shipmentId: this.item.shipmentId,
      });
      this.filterDocuments.push(result);
      this.$message.success("Removal Permit pack successfully created!");
      this.loadingContainerDocumentPack = false;
    },
    editPackWeight() {
      if (this.params.editMode()) {
        this.filteredPallets = this.params.getFilteredData();
        this.uniquePackCodes = [
          ...new Set(this.filteredPallets.map((x) => x.packCode)),
        ].map((x) => ({
          name: x,
          nettWeight: null,
          grossWeight: null,
          variance: 0.3,
        }));
        this.packCodeModal = true;
      } else {
        this.$message.error("This container is not currently in edit mode");
      }
    },
    editWeight(type) {
      if (this.params.editMode()) {
        this.weightItem = {
          type: type == "grossWeight" ? "Gross" : "Nett",
          currentGross: this.calculateGrossWeight,
          currentNett: this.calculateNettWeight,
          newNett: this.calculateNettWeight,
          newGross: this.calculateGrossWeight,
        };
        this.weightModal = true;
      } else {
        this.$message.error("This container is not currently in edit mode");
      }
    },
    exportCSV() {
      this.params.exportCSV();
    },
    exportExcel() {
      this.params.exportExcel();
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1).toLowerCase();
      }
      if (result && result.toLowerCase() == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    async loadDocuments() {
      this.loadingDocuments = true;
      this.filterDocuments = await this.$API.getContainerDocuments(
        this.item.id
      );
      this.loadingDocuments = false;
    },
    async getAvailableSources() {
      this.loadingDataSource = true;
      let result = await this.$API.getAvailableStockSources(this.item.id);
      this.activeSources = await this.$API.getActiveStockSources(this.item.id);
      for (let i = 0; i < result.length; i++) {
        let find = this.activeSources.find(
          (x) => x.source == result[i].type && x.stockFileId == result[i].id
        );
        result[i].active = find ? true : false;
        if (result[i].sourceType == "stockFile") {
          if (typeof result[i].contents == "string") {
            let data = await axios.get(result[i].contents);
            if (data && data.data) {
              result[i].contents = data.data;
            }
          }
        } else if (result[i].sourceType == "stockFileContainer") {
          for (let j = 0; j < result[i].stockFileContainers.length; j++) {
            if (typeof result[i].stockFileContainers[j].contents == "string") {
              let data = await axios.get(
                result[i].stockFileContainers[j].contents
              );
              if (data && data.data) {
                result[i].stockFileContainers[j].contents = data.data;
              }
            }
          }
        }
      }
      this.availableSources = result;
      this.loadingDataSource = false;
    },
    getRowId(params) {
      return params.data.name;
    },
    onCellValueChanged(params) {
      const rowNode = this.gridApi.getRowNode(params.data.name);
      if (rowNode) {
        rowNode.setDataValue(params.colDef.field, params.newValue);
        this.gridApi.refreshCells({ rowNodes: [rowNode] });
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    refreshContainer() {
      setTimeout(() => {
        this.params.containerSourceChanged(this.item.id);
      }, 250);
      setTimeout(()=>{
      this.detail = this.params.detail()
      this.removalPermitKey ++ 
      }, 2000)
    },
    resetTable() {
      this.params.resetTable();
    },
    setPackCodeWeights() {
      let weights = this.uniquePackCodes.filter(
        (x) => x.nettWeight || x.grossWeight
      );
      weights = weights.map((x) => {
        return {
          name: x.name,
          nettWeight: x.nettWeight ? parseFloat(x.nettWeight) : null,
          grossWeight: x.grossWeight ? parseFloat(x.grossWeight) : null,
        };
      });
      for (let i = 0; i < weights.length; i++) {
        let filtered = this.filteredPallets.filter(
          (x) => x.packCode == weights[i].name && x.noCartons
        );
        for (let j = 0; j < filtered.length; j++) {
          if (weights[i].nettWeight) {
            filtered[j].nettWeight =
              Math.round(weights[i].nettWeight * filtered[j].noCartons * 100) /
              100;
          }
          if (weights[i].grossWeight) {
            filtered[j].grossWeight =
              Math.round(weights[i].grossWeight * filtered[j].noCartons * 100) /
              100;
          }
        }
      }
      this.params.setWeights(this.filteredPallets);
      this.packCodeModal = false;
      this.uniquePackCodes = [];
    },
    async submitPermitFiles() {
      let pending = this.removalPermitList;
      for (let i = 0; i < pending.length; i++) {
        let url = await URL.createObjectURL(pending[i].file);
        this.toDataUrl(url, (data) => {
          let obj = {
            name: pending[i].name,
            data: data,
            type: pending[i].type,
            fileIndex: i,
            shipmentId: this.$route.params.shipmentId,
          };
          this.submitUpload(obj);
        });
      }
    },
    async submitRemovalPermit() {
      this.removalPermitItem.permitDocumentId =
        this.removalPermitItem.selectedDocument.id;
      let expiry = this.removalPermitItem.selectedDate.split("-");
      this.removalPermitItem.permitExpiryDate = new Date(
        expiry[0],
        expiry[1],
        0
      );
      this.submittingPermit = true;
      await this.$API.submitRemovalPermit(this.removalPermitItem);
      this.$message.success("Successfully updated removal permit!");
      this.removalPermitModal = false;
      this.removalPermitItem = {};
      this.submittingPermit = false;
      setTimeout(() => {
        this.params.refreshContainerDetail()
      }, 250);
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async submitUpload(obj) {
      let Api = axios.create({
        baseURL:
          process.env.NODE_ENV == "production"
            ? "https://api.loglive.ai"
            : process.env.NODE_ENV == "staging"
            ? "https://api.loglive.ai"
            : "http://localhost:3000",
        headers: {
          Authorization: `${this.$store.state.token}`,
          apikey: `${this.$store.state.orgToken}`,
        },
      });
      let result = await Api.post("/upload/document", obj);
      this.pallet.permitDocumentId = result.data.id;
      this.pallet.permitDocument = result.data;
      await this.$API.updatePallet({
        id: this.pallet.id,
        permitDocumentId: this.pallet.permitDocumentId,
      });
      this.removalPermitList = [];
      this.removalPermitModal = false;
    },
    async updateDataSource() {
      if (this.params.editMode()) {
        this.$confirm(
          `Are you sure you want to update the data source ?`,
          "Confirm",
          {
            center: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "plain",
          }
        ).then(async () => {
          try {
            this.savingDataSource = true;
            let active = this.availableSources.filter((x) => x.active);
            let obj = active.map((x) => ({
              source: x.type,
              type: x.type,
              id: x.id,
              sourceType: x.sourceType,
              contents:
                x.sourceType == "stockFileContainer"
                  ? x.stockFileContainers[0].contents
                  : x.contents,
            }));
            await this.$API.updateDocumentSource({
              bookingContainerId: this.item.id,
              source: obj,
            });
            this.dataSourceChange = false;
            this.savingDataSource = false;
            this.refreshContainer();
            this.$message.success("Successfully updated data source!");
          } catch (e) {
            console.log(e);
            this.$message({
              type: "error",
              message: "There was an error changing the document source.",
            });
            this.savingDataSource = false;
          }
        });
      } else {
        this.$message.error("This container is not currently in edit mode");
      }
    },
    updateWeight() {
      let pallets = this.item.containerPallets;
      let unique = [...new Set(pallets.map((x) => x.sscc))];

      if (this.weightItem.currentGross != this.weightItem.newGross) {
        let calculatedWeight =
          parseFloat(this.weightItem.newGross) / unique.length;
        for (let i = 0; i < unique.length; i++) {
          let filtered = pallets.filter((x) => x.sscc == unique[i]);
          let totalCartons = filtered
            .map((x) => parseInt(x.noCartons))
            .reduce((a, b) => a + b);
          for (let j = 0; j < filtered.length; j++) {
            filtered[j].grossWeight = (
              Math.round(
                calculatedWeight *
                  (parseInt(filtered[j].noCartons) / totalCartons) *
                  100
              ) / 100
            ).toFixed(2);
          }
        }
      }
      if (this.weightItem.currentNett != this.weightItem.newNett) {
        let calculatedWeight =
          parseFloat(this.weightItem.newNett) / unique.length;
        for (let i = 0; i < unique.length; i++) {
          let filtered = pallets.filter((x) => x.sscc == unique[i]);
          let totalCartons = filtered
            .map((x) => parseInt(x.noCartons))
            .reduce((a, b) => a + b);
          for (let j = 0; j < filtered.length; j++) {
            filtered[j].nettWeight = (
              Math.round(
                calculatedWeight *
                  (parseInt(filtered[j].noCartons) / totalCartons) *
                  100
              ) / 100
            ).toFixed(2);
          }
        }
      }
      this.params.setWeights(pallets);
      this.weightModal = false;
      this.weightItem = {};
    },
    viewSource(item) {
      this.sourceItem = item;
      if (this.sourceItem.sourceType == "stockFileContainer") {
        this.selectedContainer = this.sourceItem.stockFileContainers[0];
      }
      this.sourceModal = true;
    },
  },
};
</script>