<template>
  <div>
    <div v-if="group">
      <span>{{ group }}</span>
    </div>
    <div v-else>
      <div
        v-if="editable"
        style="cursor: pointer"
        @click="editProductModal = true"
      >
        <span v-if="item">
          {{ item.name }}
        </span>
        <span v-else>-</span>
      </div>
      <div v-else>
        <span v-if="item">
          {{ item.name }}
        </span>
        <span v-else>-</span>
      </div>
    </div>

    <v-dialog v-model="editProductModal" width="500px">
      <v-card v-if="editProductModal">
        <v-toolbar flat dense>
          <v-toolbar-title>Edit Product</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="editProductModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            hide-details
            outlined
            dense
            prepend-inner-icon="search"
            v-model="search"
          ></v-text-field>
          <v-list dense>
            <v-virtual-scroll
              :items="filteredProducts"
              :bench="5"
              :item-height="45"
              height="400px"
            >
              <template v-slot="{ item }">
                <v-list-item :key="item.id" @click="setProduct(item)">
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 12px">
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 11px"
                      v-if="item.parentProduct"
                    >
                      Parent: {{ item.parentProduct.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-virtual-scroll>
            <v-list-item v-if="filteredProducts.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey">
                  No products found
                </span>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item v-for="product in filteredProducts" :key="product.id">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ product.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle style="font-size: 11px" v-if="product.parentProduct">
                                   Parent: {{ product.parentProduct.name }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="filteredProducts.length ==0">
                            <v-list-item-content class="text-center">
                                <span style="font-size: 12px; color: grey">
                                    No products found
                                </span>
                            </v-list-item-content>
                        </v-list-item> -->
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    editable: false,
    editProductModal: false,
    filteredProducts: [],
    group: null,
    item: null,
    parentProductId: null,
    products: [],
    productModal: false,
    search: "",
    searchTimeout: null,
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        let result = this.products;
        if (this.parentProductId) {
          result = result.filter(
            (x) => x.parentProductId == this.parentProductId || x.productId == this.parentProductId
          );
        }
        if (val) {
          result = result.filter(
            (x) => x.name && x.name.toLowerCase().includes(val.toLowerCase())
          );
        }
        this.filteredProducts = result;
      }, 50);
    },
  },
  mounted() {
    this.editable = this.params.isEditable;
    this.products = this.params.products ?? [];
    this.filteredProducts = this.products;
    this.parentProductId = this.params.parentProductId;
    if (this.parentProductId) {
      this.filteredProducts = this.products.filter(
        (x) => x.parentProductId == this.parentProductId
      );
    }
    let productId = this.params.productId
      ? this.params.productId
      : this.params.data
      ? this.params.data.productId
      : null;
    this.item = this.products.find((x) => x.id == productId);
    if (!this.params.data) {
      this.group = this.params.valueFormatted;
    }
  },
  methods: {
    setProduct(product) {
      this.params.setProduct(this.params.data, product);
    },
  },
};
</script>
