<template>
  <div v-if="detail">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
          <v-btn @click="editPallet(detail)" icon small v-on="on">
            <v-icon small>attach_file</v-icon>
          </v-btn>
      </template>
      <span>Add Attachment</span>
    </v-tooltip>
    <v-tooltip top v-if="detail.consignmentNote">
      <template v-slot:activator="{ on }">
        <v-badge
          v-on="on"
          bordered
          :color="'success'"
          overlap
          bottom
          dot
          offset-x="10"
          offset-y="15"
        >
          <v-btn @click="viewConsignmentNote(detail)" icon small v-on="on">
            <v-icon small>description</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <span>Consignment Note</span>
    </v-tooltip>

    <v-tooltip top v-if="detail.permitDocument">
      <template v-slot:activator="{ on }">
        <v-badge
          v-on="on"
          bordered
          :color="validPermit(detail.permitExpiryDate) ? 'success' : 'red'"
          overlap
          bottom
          dot
          offset-x="10"
          offset-y="15"
        >
          <v-btn icon small v-on="on" @click="viewRemovalPermit(detail)">
            <v-icon small>agriculture</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <span
        >Removal Permit: {{ detail.permitNo }} expires
        {{ detail.permitExpiryDate }}</span
      >
    </v-tooltip>

    <v-tooltip top v-if="checkMetadata('Titan Ready', detail.palletMetadata)">
      <template v-slot:activator="{ on }">
        <v-badge
          v-on="on"
          bordered
          :color="
            verify('Titan Ready', detail.palletMetadata) ? 'success' : 'red'
          "
          overlap
          bottom
          dot
          offset-x="10"
          offset-y="15"
        >
          <v-btn icon small v-on="on" @click="viewTitan(detail)">
            <v-img
              style="height: 24px; width: 24px"
              preload="rel"
              contain
              src="../../../assets/ppecbwhite.svg"
            ></v-img>
          </v-btn>
        </v-badge>
      </template>
      <span
        >Titan Check:
        {{ metadataValue("Titan Ready", detail.palletMetadata) }}</span
      >
    </v-tooltip>

    <v-tooltip
      top
      v-if="checkMetadata('Phytclean Verified', detail.palletMetadata)"
    >
      <template v-slot:activator="{ on }">
        <v-badge
          v-on="on"
          bordered
          :color="
            verify('Phytclean Verified', detail.palletMetadata)
              ? 'success'
              : 'red'
          "
          overlap
          bottom
          dot
          offset-x="10"
          offset-y="15"
        >
          <v-btn icon small v-on="on" @click="viewPhytcleanCertificate(detail)">
            <v-img
              style="height: 24px; width: 24px"
              preload="rel"
              contain
              src="../../../assets/PhytClean.png"
            ></v-img>
          </v-btn>
        </v-badge>
      </template>
      <span
        >Phytclean verification:
        {{ metadataValue("Phytclean Verified", detail.palletMetadata) }}</span
      >
    </v-tooltip>
    <v-dialog
      v-model="consignmentNoteModal"
      :fullscreen="$vuetify.breakpoint.mobile || consignmentNoteFullscreen"
      width="1500px"
    >
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px" color="transparent">
            {{ documentItem.sscc }}
            <span v-if="displayPage">(Detected on page {{ displayPage }})</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="documentItem.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn>
          <v-btn
            @click="consignmentNoteFullscreen = !consignmentNoteFullscreen"
            icon
            ><v-icon v-if="!consignmentNoteFullscreen">fullscreen</v-icon
            ><v-icon v-else>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon @click="downloadDocument(documentItem)">
            <v-icon>download</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="
              (consignmentNoteModal = false),
                (documentItem = {}),
                (displayPage = null),
                (consignmentNoteTab = 0)
            "
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text
          v-if="documentItem && documentItem.pallet"
          class="my-0 py-0"
        >
          <v-container :key="itemKey" class="mx-0 px-0" style="width: 100%">
            <v-row justify="center">
              <v-col cols="12" md="3">
                <v-list dense>
                  <v-subheader style="font-size: 14px"
                    >Pallet Detail</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> PUC </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.farm }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> PHC </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.packHouse }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Orchard </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.orchard }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Production Area </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.productionArea }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Phyto Data </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.phytoReference }}
                    </v-list-item-action-text>
                  </v-list-item>

                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Container No. </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.containerNo }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Commodity </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.commodity }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Variety </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.variety }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Flesh Colour </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.fleshColour }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Count </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.countSize }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ documentItem.pallet.packType ?? "Cartons" }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.noCartons }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Grade/Class </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      <span
                        v-if="documentItem.pallet.gradeClassCode.includes(1)"
                      >
                        {{ 1 }}
                      </span>
                      <span
                        v-else-if="
                          documentItem.pallet.gradeClassCode.includes(2)
                        "
                      >
                        {{ 2 }}
                      </span>
                      <span
                        v-else-if="
                          documentItem.pallet.gradeClassCode.includes('P')
                        "
                      >
                        {{ "P" }}
                      </span>
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Manifest No </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.inspectionManifestNo }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Inspection Date </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.inspectionDate }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Inspection Point </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.inspectionPoint }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Nett Weight </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.nettWeight }} KG
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Gross Weight </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.grossWeight }} KG
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item style="height: 15px">
                    <v-list-item-content>
                      <v-list-item-title> Stuff Date </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ documentItem.pallet.stuffDate }}
                    </v-list-item-action-text>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="9" class="text-center">
                <div style="height: 80vh; overflow-y: auto">
                  <div
                    v-if="
                      documentItem.fileType == 'application/pdf' &&
                      !documentItem.loadingImage
                    "
                  >
                    <div v-if="displayPage">
                      <v-tabs v-model="consignmentNoteTab">
                        <v-tab> Detected Page </v-tab>
                        <v-tab> Full Document </v-tab>
                      </v-tabs>
                      <div v-if="consignmentNoteTab == 0" class="mt-1">
                        <VuePdfEmbed
                          :page="displayPage"
                          :rotation="rotate"
                          :width="consignmentNoteFullscreen ? 1200 : 900"
                          :source="documentItem.url"
                        />
                      </div>
                      <div v-else class="mt-1">
                        <VuePdfEmbed
                          :rotation="rotate"
                          :width="consignmentNoteFullscreen ? 1200 : 900"
                          :source="documentItem.url"
                        />
                      </div>
                    </div>
                    <div v-else>
                      <VuePdfEmbed
                        :rotation="rotate"
                        :width="consignmentNoteFullscreen ? 1200 : 800"
                        :source="documentItem.url"
                      />
                    </div>
                  </div>

                  <v-img
                    v-else-if="
                      !documentItem.loadingImage &&
                      documentItem.fileType &&
                      documentItem.fileType.includes('image')
                    "
                    :style="{ transform: `rotate(${rotate}deg)` }"
                    :src="documentItem.fullFile"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="permitModal"
      :fullscreen="$vuetify.breakpoint.mobile"
      width="1200px"
    >
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px"> </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="documentItem.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn>
          <!-- <v-btn v-if="documentItem.name" text @click="$refs.pdf[0].print()">
            <v-icon>print</v-icon>
          </v-btn> -->
          <v-btn
            @click="consignmentNoteFullscreen = !consignmentNoteFullscreen"
            icon
            ><v-icon v-if="!consignmentNoteFullscreen">fullscreen</v-icon
            ><v-icon v-else>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon @click="downloadDocument(documentItem)">
            <v-icon>download</v-icon>
          </v-btn>
          <v-btn icon @click="(permitModal = false), (documentItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text class="my-0 py-0">
          <v-container class="mx-0 px-0" style="width: 100%">
            <v-row justify="center">
              <v-col cols="12" md="9" class="text-center">
                <div style="height: 75vh; overflow-y: auto">
                  <div
                    v-if="
                      documentItem.type == 'application/pdf' &&
                      !documentItem.loadingImage
                    "
                  >
                    <VuePdfEmbed
                      :rotation="rotate"
                      :width="consignmentNoteFullscreen ? 1200 : 800"
                      :source="documentItem.url"
                    />
                  </div>

                  <v-img
                    v-else-if="
                      !documentItem.loadingImage &&
                      documentItem.type &&
                      documentItem.type.includes('image')
                    "
                    :style="{ transform: `rotate(${rotate}deg)` }"
                    :src="documentItem.url"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="phytcleanModal"
      :fullscreen="$vuetify.breakpoint.mobile"
      width="1200px"
    >
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px"> </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="documentItem.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn>
          <!-- <v-btn v-if="documentItem.name" text @click="$refs.pdf[0].print()">
            <v-icon>print</v-icon>
          </v-btn> -->
          <v-btn
            @click="consignmentNoteFullscreen = !consignmentNoteFullscreen"
            icon
            ><v-icon v-if="!consignmentNoteFullscreen">fullscreen</v-icon
            ><v-icon v-else>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon @click="downloadDocument(documentItem)">
            <v-icon>download</v-icon>
          </v-btn>
          <v-btn icon @click="(phytcleanModal = false), (documentItem = {})"
            >X</v-btn
          >
          {{ documentItem.type }}
        </v-toolbar>
        <v-card-text>
          <v-container class="mx-0 px-0" style="width: 100%; height: 75vh">
            <!-- <v-row justify="center" fill-height> -->
            <div style="height: 75vh; overflow-y: auto">
              <div v-if="documentItem.fileType == 'application/pdf'">
                <pdf ref="pdf" :src="documentItem.file"></pdf>
              </div>

              <v-img
                v-else-if="
                  documentItem.fileType &&
                  documentItem.fileType.includes('image')
                "
                :style="{ transform: `rotate(${rotate}deg)` }"
                :src="documentItem.file"
              ></v-img>
            </div>
            <!-- </v-row> -->
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="titanReview"
      width="750px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Titan Data Review</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(titanReview = false), (titanItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text
          v-if="titanItem && titanItem.data"
          style="max-height: 60vh; overflow-y: auto"
        >
          <ag-grid-vue
            :animateRows="true"
            :autoSizeStrategy="{
              type: 'fitGridWidth',
              columnLimits: [],
            }"
            style="width: 100%; height: 300px"
            :columnDefs="columns"
            :rowData="titanItem.data"
          >
          </ag-grid-vue>
        </v-card-text>
        <v-card-text v-else class="text-center">
          <span style="color: grey">Pallet not inspected on Titan</span>
        </v-card-text>
      </v-card></v-dialog
    >

    <v-dialog
      v-model="palletModal"
      persistent
      width="750px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          Pallet: {{ pallet.sscc }}
          <v-spacer></v-spacer>
          <v-btn text @click="(palletModal = false), (pallet = {})">X</v-btn>
        </v-card-title>
        <v-card-text
          style="max-height: 65vh; overflow-y: auto"
          class="my-2"
          :key="refreshKey"
        >
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-card v-if="pallet.consignmentNote">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-list-item @click="consignmentNoteAddModal = true">
                        <v-list-item-action
                          class="ml-0 pl-0"
                          style="width: 20px"
                        >
                          <v-img
                            class="mr-2"
                            style="height: 30px; width: 30px"
                            :src="getIcon(pallet.consignmentNote.name)"
                          >
                          </v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-left"
                            style="font-size: 14px"
                          >
                            {{ pallet.consignmentNote.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px"
                            v-if="pallet.consignmentNote.user"
                          >
                            <v-icon small>person</v-icon>
                            {{ pallet.consignmentNote.user.firstname }}
                            {{ pallet.consignmentNote.user.surname }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 12px">
                            {{
                              $Format.formatDate(
                                pallet.consignmentNote.createdAt
                              ).default
                            }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 12px">
                            Consignment Note
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card
                v-else
                style="border: 0.5px dotted grey; border-radius: 20px"
                @click="consignmentNoteAddModal = true"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-icon>add</v-icon>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <h3>Consignment Note</h3>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card v-if="pallet.permitDocument">
                <v-card-text>
                  <v-list-item @click="removalPermitModal = true">
                    <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                      <v-img
                        class="mr-2"
                        style="height: 30px; width: 30px"
                        :src="getIcon(pallet.permitDocument.name)"
                      >
                      </v-img>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-left"
                        style="font-size: 14px"
                      >
                        {{ pallet.permitDocument.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        style="font-size: 12px"
                        v-if="pallet.permitDocument.user"
                      >
                        <v-icon small>person</v-icon>
                        {{ pallet.permitDocument.user.firstname }}
                        {{ pallet.permitDocument.user.surname }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        {{
                          $Format.formatDate(pallet.permitDocument.createdAt)
                            .default
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        Removal Permit
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
              <v-card
                v-else
                style="border: 0.5px dotted grey; border-radius: 20px"
                @click="removalPermitModal = true"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-icon>add</v-icon>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <h3>Removal Permit</h3>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card></v-dialog
    >

    <v-dialog v-model="removalPermitModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Add Removal Permit</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(removalPermitModal = false)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <!-- <v-col cols="12" sm="6" v-if="containerDocuments.length > 0">
              <v-list>
                <v-subheader style="font-size: 16px">Container Documents</v-subheader>
                <v-list-item v-if="containerDocuments.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No documents</span>
                  </v-list-item-content></v-list-item>
                <v-list-item v-for="document in containerDocuments" :key="document.id"
                  @click="attachDocument('Removal Permit', document)">
                  <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                    <v-img class="mr-2" style="height: 30px; width: 30px" :src="getIcon(document.name)">
                    </v-img>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="text-left" style="font-size: 14px">
                      {{ document.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px" v-if="document.user">
                      <v-icon small>person</v-icon>
                      {{ document.user.firstname }}
                      {{ document.user.surname }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px" v-else>
                      System Generated
                    </v-list-item-subtitle>

                    <v-list-item-subtitle style="font-size: 12px" v-if="document.isFinancialDocument">
                      <v-icon small class="mr-1">account_balance</v-icon>
                      Financial Document
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px">
                      {{ formatDate(document.createdAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

              </v-list>
            </v-col> -->
            <v-col cols="12" sm="6" class="text-center">
              <v-btn class="mx-1" small color="blue" :loading="uploadingFile" @click="addPermitFiles()"><v-icon
                  class="mr-2">cloud_upload</v-icon> Upload
                File</v-btn>
              <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="true" :drop-directory="true"
                v-model="removalPermitList" ref="permitUploader">
              </file-upload>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import FileUpload from "vue-upload-component";
import pdf from "vue-pdf";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  components: {
    AgGridVue,
    FileUpload,
    pdf,
    VuePdfEmbed,
  },
  data: () => ({
    columns: [
      {
        colId: "field",
        field: "field",
      },
      {
        colId: "loglive",
        field: "local",
        headerName: "Loglive",
        cellStyle: (p) => {
          return p.data && p.data.local == p.data.titan
            ? { backgroundColor: "rgba(7, 138, 3, 0.2)" }
            : p.data
            ? { backgroundColor: "rgba(186, 47, 0, 0.2)" }
            : "";
        },
      },
      {
        colId: "titan",
        field: "titan",
        cellStyle: (p) => {
          return p.data && p.data.local == p.data.titan
            ? { backgroundColor: "rgba(7, 138, 3, 0.2)" }
            : p.data
            ? { backgroundColor: "rgba(186, 47, 0, 0.2)" }
            : "";
        },
      },
    ],
    consignmentNoteFullscreen: false,
    consignmentNoteModal: false,
    consignmentNoteTab: 0,
    detail: null,
    displayPage: null,
    documentItem: {},
    fileList: [],
    item: null,
    itemKey: 0,
    palletModal: false,
    pallet: {},
    permitModal: false,
    phytcleanModal: false,
    refreshKey: 100,
    removalPermitList: [],
    removalPermitModal: false,
    rotate: 0,
    titanReview: false,
    titanItem: {},
    uploadingFile: false,
  }),
  mounted() {
    this.item = this.params.data;
    let item = this.params.data;
    if(this.item){
        let detail = this.params.detail()
        let findDetail = detail
      ? detail.containerPallets.find((x) => x.id == item.id)
      : null;
    if (findDetail) {
      this.detail = findDetail;
    }
    }
    
  },
  watch : {
    fileList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitFiles();
        }
      },
    },
    removalPermitList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitPermitFiles();
        }
      },
    },
  },
  methods: {
    addFiles() {
      this.$refs.uploader.$el.children[0].click();
    },
    addPermitFiles() {
      this.$refs.permitUploader.$el.children[0].click();
    },
    checkMetadata(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find ? true : false;
    },
    
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    editPallet(pallet) {
      this.pallet = pallet;
      this.palletModal = true;
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1).toLowerCase();
      }
      if (result && result.toLowerCase() == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    metadataValue(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find ? find.value : "";
    },
    async submitFiles() {
      this.uploadingFile = true

      let pending = this.consignmentNoteAddModal ? this.fileList : this.removalPermitList;
      console.log(this.consignmentNoteAddModal, pending, this.removalPermitList)
      for (let i = 0; i < pending.length; i++) {
        let url = await URL.createObjectURL(pending[i].file);
        this.toDataUrl(url, (data) => {
          let obj = {
            name: pending[i].name,
            data: data,
            type: pending[i].type,
            fileIndex: i,
            shipmentId: this.$route.params.shipmentId,
          };
          this.submitUpload(obj);
        });
      }
    },
    async submitPermitFiles() {
      this.uploadingFile = true
      let pending = this.removalPermitList;
      for (let i = 0; i < pending.length; i++) {
        let url = await URL.createObjectURL(pending[i].file);
        this.toDataUrl(url, (data) => {
          let obj = {
            name: pending[i].name,
            data: data,
            type: pending[i].type,
            fileIndex: i,
            shipmentId: this.$route.params.shipmentId,
          };
          this.submitUpload(obj);
        });
      }
    },
    async submitUpload(obj) {
      let Api = axios.create({
        // baseURL: "http://localhost:3000",
        baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai': 'http://localhost:3000',
        headers: {
          Authorization: `${this.$store.state.token}`,
          apikey: `${this.$store.state.orgToken}`,
        },
      });
      let result = await Api.post("/upload/document", obj);
      if (this.consignmentNoteAddModal) {
        this.pallet.consignmentNoteId = result.data.id;
        this.pallet.consignmentNote = result.data;
        await this.$API.updatePallet({
          id: this.pallet.id,
          consignmentNoteId: this.pallet.consignmentNoteId,
          consignmentNotePage: this.pallet.consignmentNotePage
        })
      } else if (this.removalPermitModal) {
        this.pallet.permitDocumentId = result.data.id;
        this.pallet.permitDocument = result.data;
        await this.$API.updatePallet({
          id: this.pallet.id,
          permitDocumentId: this.pallet.permitDocumentId,
        })
      }

      this.fileList = [];
      this.removalPermitList = []
      this.consignmentNoteAddModal = false
      this.removalPermitModal = false
      this.uploadingFile = false
      this.refreshKey++
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    validPermit(date) {
      if (date) {
        let expiry = new Date(date);
        let today = new Date();
        return expiry > today;
      } else {
        return false;
      }
    },
    verify(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find ? find.value == "Passed" : false;
    },
    async viewConsignmentNote(item) {
      this.documentItem = item.consignmentNote;
      this.documentItem.pallet = { ...item };
      delete this.documentItem.pallet.consignmentNote;
      this.documentItem.sscc = item.sscc;
      this.documentItem.loadingImage = true;
      this.consignmentNoteModal = true;
      this.documentItem.url = null;
      this.documentItem.url = await this.$API.getDocumentLink({
        id: item.consignmentNote.id,
        key: item.consignmentNote.key,
      });
      this.documentItem.fileType = this.documentItem.type;
      this.displayPage = item.consignmentNotePage ?? null;
      this.documentItem.name = item.consignmentNote.name;
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    async viewPhytcleanCertificate(item) {
      let meta = item.palletMetadata.find((x) => x.key == "Phytclean Verified");
      this.documentItem = item;
      let file = await this.$API.downloadFile({
        key: JSON.parse(meta.data),
      });
      this.phytcleanModal = true;

      this.documentItem.name = item.sscc;
      this.documentItem.file = file.file;
      this.documentItem.fileType = file.fileType;
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    async viewRemovalPermit(item) {
      this.documentItem = item.permitDocument;
      this.documentItem.loadingImage = true;
      this.permitModal = true;
      this.documentItem.url = null;
      this.documentItem.url = await this.$API.getDocumentLink({
        id: item.permitDocument.id,
        key: item.permitDocument.key,
      });
      this.documentItem.name = item.sscc;
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    viewTitan(item) {
      let find = item.palletMetadata.find((x) => x.key == "Titan Ready");
      this.titanItem = find;
      if (typeof this.titanItem.data == "string") {
        this.titanItem.data = JSON.parse(this.titanItem.data);
      }
      this.titanReview = true;
    },
  },
};
</script>
